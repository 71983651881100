import "./WorkCard.css";

interface WorkCardProps {
	titleImage: string;
	backgroundImage: string;
	logo: string;
	onClick: React.MouseEventHandler<HTMLAnchorElement>;
	isMobile: boolean;
}

const WorkCard = ({
	titleImage,
	backgroundImage,
	logo,
	onClick,
	isMobile,
}: WorkCardProps) => {
	const cardHeight = isMobile ? 200 : 320;

	const cardStyle: React.CSSProperties & { [key: string]: any } = {
		"--card-height": `${cardHeight}px`, // Type assertion here
		"--card-width": `calc(${cardHeight}px / 1.5)`, // Type assertion here
		transition: "all 0.5s",
	};

	return (
		<a onClick={onClick} target="_blank" style={{ margin: 16 }}>
			<div className="card" style={cardStyle}>
				<div className="wrapper">
					<img
						alt="img"
						src={backgroundImage}
						className="cover-image"
					/>
				</div>
				<img
					alt="img"
					src={titleImage}
					className="title-image"
				/>
				<img alt="img" src={logo} className="logo" />
			</div>
		</a>
	);
};

export default WorkCard;
