import "./PhoneModal.css";
import Modal from "../Modal/Modal";
import PhoneGif from "../../assets/icons/phone.gif";
import CloseIcon from "../../assets/icons/close.png";
import ArrowIcon from "../../assets/icons/arrow.png";
import { Zoom } from "@mui/material";
import { useSpringCarousel } from "react-spring-carousel";
import { useEffect } from "react";
interface PhoneModalProps {
	isVisible: boolean;
	isMobile: boolean;
	onCloseModal: () => void;
	selectedAppImages: {
		id: string;
		renderItem: React.JSX.Element;
	}[];
}

const PhoneModal = ({
	isVisible = false,
	onCloseModal,
	isMobile,
	selectedAppImages,
}: PhoneModalProps) => {
	const {
		carouselFragment,
		slideToPrevItem,
		slideToNextItem,
		slideToItem,
	} = useSpringCarousel({
		itemsPerSlide: 1,
		// initialStartingPosition: "center",
		withLoop: true,
		items: selectedAppImages.map((i) => ({
			id: i.id,
			renderItem: (
				<div key={i.id} style={{ cursor: "grab" }}>
					{i.renderItem}
				</div>
			),
		})),
	});

	useEffect(() => {
		slideToItem(0);
	}, [selectedAppImages]);
	return (
		<Modal
			isVisible={isVisible}
			onBackdropClick={() => {}}
			containerStyle={{
				alignSelf: "center",
				position: "absolute",
				height: "100vh",
				width: "100vw",
				bottom: 0,
				top: 0,
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<div className="container">
				<Zoom in timeout={{ enter: 1000, exit: 500 }}>
					<div
						className="close-button"
						onClick={() => {
							onCloseModal();
						}}
					>
						<img
							alt="img"
							style={{
								width: 24,
								height: 24,
								alignSelf: "center",
							}}
							src={CloseIcon}
						/>
					</div>
				</Zoom>
				<div
					className="next-button"
					onClick={() => slideToNextItem()}
				>
					<img
						alt="img"
						style={{
							width: 16,
							height: 64,
							alignSelf: "center",
							pointerEvents: "none",
						}}
						src={ArrowIcon}
					/>
				</div>
				<div
					className="prev-button"
					onClick={() => slideToPrevItem()}
				>
					<img
						alt="img"
						style={{
							width: 16,
							height: 64,
							alignSelf: "center",
							pointerEvents: "none",
						}}
						src={ArrowIcon}
					/>
				</div>
				<div className="phone">
					<div className="content">
						{/* <img alt="img"
								style={{
									width: "100%",
									height: "100%",
									objectFit: "contain",
								}}
								src={PhoneGif}
							/> */}
						{carouselFragment}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default PhoneModal;
