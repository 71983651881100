import React, { useEffect } from "react";
import "./TimelineCard.css";
import { PageBehaviour, ToolItem } from "../../assets/allTypes";
import { animated, useSpring } from "@react-spring/web";
import { Fade, Grow } from "@mui/material";
import { appList } from "../../assets/DATA";

interface TimelineCardProps {
	title: string;
	description: string;
	dateRange: string[];
	tools: ToolItem[];
	appLogo: {
		appId: string;
		image: any;
	}[];
	index: number;
	pageBehaviour: PageBehaviour;
	isMobile: boolean;
	onLogoClick: (
		appImages: {
			id: string;
			renderItem: React.JSX.Element;
		}[]
	) => void;
}

const TimelineCard: React.FC<TimelineCardProps> = ({
	title,
	description,
	dateRange,
	tools,
	appLogo,
	index,
	pageBehaviour,
	isMobile,
	onLogoClick,
}) => {
	const [logo, setLogo] = useSpring(() => ({
		from: { x: -400 },
	}));

	useEffect(() => {
		if (pageBehaviour === "EXPERIENCE") {
			setTimeout(() => {
				setLogo({
					to: { x: 0 },
					config: {
						tension: 500,
						friction: 150,
					},
				});
			}, index * 100);
		} else {
			setLogo({
				to: { x: -400 },
				config: {
					tension: 500,
					friction: 150,
				},
			});
		}
	}, [pageBehaviour]);
	const toolItem = (item: ToolItem, index: number) => {
		return (
			<Grow
				key={index}
				in={pageBehaviour === "EXPERIENCE"}
				timeout={{ enter: index * 500, exit: 0 }}
			>
				<div
					className="tool-box"
					style={{
						backgroundColor: item.color,
					}}
				>
					<p className="tool-name">{item.name}</p>
				</div>
			</Grow>
		);
	};
	return (
		<Fade
			in={pageBehaviour === "EXPERIENCE"}
			timeout={{ enter: index * 750, exit: 500 }}
		>
			<div className="timeline-card-wrapper">
				<div className="timeline-card">
					<div className="timeline-content">
						<div className="timeline-card-header-container">
							<div className="timeline-card-title-container">
								{isMobile &&
									appLogo.map(
										(logo, index) => (
											<img
												alt="img"
												onClick={() => {
													onLogoClick(
														appList.find(
															(
																item
															) =>
																item.appId ===
																logo.appId
														)
															?.appImages ??
															[]
													);
												}}
												key={
													index
												}
												src={
													logo.image
												}
												className="app-logo-mobile"
											/>
										)
									)}
								<h3 className="timeline-card-title">
									{title}
								</h3>
							</div>
							<div className="month-list-container">
								<div className="month-circle">
									<p className="month-text">
										{dateRange[0] +
											" - " +
											dateRange[
												dateRange.length -
													1
											]}
									</p>
								</div>
								{/* {dateRange.map(
									(item, index) => (
										<div className="month-circle">
											<p className="month-text">
												{item}
											</p>
										</div>
									)
								)} */}
							</div>
						</div>
						<p className="timeline-card-description">
							{description}
						</p>
					</div>
					<div className="tools-wrapper">
						{tools?.map((item, index) =>
							toolItem(item, index)
						)}
					</div>
				</div>
				{!isMobile && (
					<animated.div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							backgroundColor: "#2e3a4b",
							borderTopRightRadius: 12,
							borderBottomRightRadius: 12,
							marginLeft: -8,
							paddingRight: 24,
							paddingLeft: 32,
							...logo,
						}}
					>
						{appLogo.map((logo, index) => (
							<img
								alt="img"
								onClick={() => {
									onLogoClick(
										appList.find(
											(item) =>
												item.appId ===
												logo.appId
										)?.appImages ?? []
									);
								}}
								key={index}
								src={logo.image}
								className="app-logo"
							/>
						))}
					</animated.div>
				)}
			</div>
		</Fade>
	);
};

export default TimelineCard;
