import "./HomePage.css";
import CodeAnimation from "../../assets/animations/code.json";
import Lottie from "lottie-react";
import { useEffect, useRef, useState } from "react";
import { animated, useSpring } from "@react-spring/web";
import { windowFullWidth } from "../../utils/UIHelper";
import ReactLogo from "../../assets/icons/react-logo.png";
import { Fade, Zoom } from "@mui/material";
import WorkCard from "../../components/WorkCard/WorkCard";
import PhoneModal from "../../components/PhoneModal/PhoneModal";
import TimelineCard from "../../components/TimelineCard/TimelineCard";
import { appList, contactInfo, experienceList } from "../../assets/DATA";
import { PageBehaviour } from "../../assets/allTypes";
import RowInfo from "../../components/RowInfo/RowInfo";

const HomePage = () => {
	const experienceListRef: React.LegacyRef<HTMLDivElement> = useRef(null);
	const workListRef: React.LegacyRef<HTMLDivElement> = useRef(null);
	const [pageBehaviour, setPageBehaviour] = useState<PageBehaviour>("HOME");
	const [phoneModalVisible, setPhoneModalVisible] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
	const [selectedAppImages, setSelectedAppImages] = useState<
		{
			id: string;
			renderItem: React.JSX.Element;
		}[]
	>([]);
	const [isLoadingAppImages, setIsLoadingAppImages] = useState(false);

	const [home, setHome] = useSpring(() => ({
		from: { x: 0 },
	}));
	const [work, setWork] = useSpring(() => ({
		from: { x: windowFullWidth },
	}));
	const [experience, setExperience] = useSpring(() => ({
		from: { x: windowFullWidth },
	}));
	const [contact, setContact] = useSpring(() => ({
		from: { x: windowFullWidth },
	}));

	useEffect(() => {
		const handleResize = () => {
			const windowWidth = window.innerWidth;
			setIsMobile(windowWidth <= 768);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		if (pageBehaviour === "HOME") {
			setHome({ to: { x: 0 } });
			setWork({ to: { x: windowFullWidth } });
			setExperience({ to: { x: windowFullWidth } });
			setContact({ to: { x: windowFullWidth } });
		} else if (pageBehaviour === "WORK") {
			setHome({ to: { x: -windowFullWidth } });
			setWork({ to: { x: 0 } });
			setExperience({ to: { x: windowFullWidth } });
			setContact({ to: { x: windowFullWidth } });
			workListRef.current?.scrollTo(0, 0);
		} else if (pageBehaviour === "EXPERIENCE") {
			setHome({ to: { x: -windowFullWidth } });
			setWork({ to: { x: -windowFullWidth } });
			setExperience({ to: { x: 0 } });
			setContact({ to: { x: windowFullWidth } });
			experienceListRef.current?.scrollTo(0, 0);
		} else if (pageBehaviour === "CONTACT") {
			setHome({ to: { x: -windowFullWidth } });
			setWork({ to: { x: -windowFullWidth } });
			setExperience({ to: { x: -windowFullWidth } });
			setContact({ to: { x: 0 } });
		}
	}, [pageBehaviour]);

	return (
		<div className="home-box">
			<div className="home-header-box">
				<p
					className={`home-header-nav-title ${
						pageBehaviour === "HOME" &&
						"home-header-selected"
					}`}
					onClick={() => setPageBehaviour("HOME")}
				>
					home
				</p>
				<p
					className={`home-header-nav-title ${
						pageBehaviour === "WORK" &&
						"home-header-selected"
					}`}
					onClick={() => setPageBehaviour("WORK")}
				>
					work
				</p>
				<p
					className={`home-header-nav-title ${
						pageBehaviour === "EXPERIENCE" &&
						"home-header-selected"
					}`}
					onClick={() => setPageBehaviour("EXPERIENCE")}
				>
					experience
				</p>
				<p
					className={`home-header-nav-title ${
						pageBehaviour === "CONTACT" &&
						"home-header-selected"
					}`}
					onClick={() => setPageBehaviour("CONTACT")}
				>
					contact
				</p>
			</div>
			{/* HOME */}
			{pageBehaviour === "HOME" && (
				<Fade
					in={pageBehaviour === "HOME"}
					timeout={{ enter: 1500, exit: 500 }}
				>
					<animated.div
						style={{
							alignSelf: "center",
							marginTop: "10vh",
							zIndex: 99,
							alignItems: "center",
							justifyContent: "center",
							position: "absolute",
							width: "100%",
							...home,
						}}
					>
						<div className="home-container">
							<p
								style={{
									color: "white",
									fontWeight: "bold",
									marginBlock: 0,
									fontSize: "clamp(40px, 10vw, 90px)",
								}}
							>
								YILMAZ YAKUT
							</p>
							<img
								alt="img"
								src={ReactLogo}
								className="react-logo"
							/>
							<Zoom in timeout={{ enter: 1000 }}>
								<p
									style={{
										color: "white",
										fontWeight: "200",
										marginBlock: 0,
										letterSpacing: 10,
										marginTop: 16,
										fontSize: "clamp(16px, 4vw, 24px)",
									}}
								>
									REACT NATIVE DEVELOPER
								</p>
							</Zoom>
							<Zoom in timeout={{ enter: 1000 }}>
								<img
									alt="img"
									src={require("../../assets/icons/avatar.png")}
									style={{
										height: 400,
										marginTop: 16,
									}}
								/>
							</Zoom>
						</div>
					</animated.div>
				</Fade>
			)}
			{/* WORK */}
			{pageBehaviour === "WORK" && (
				<Fade
					in={pageBehaviour === "WORK"}
					timeout={{ enter: 1500, exit: 500 }}
				>
					<animated.div
						style={{
							alignSelf: "center",
							display: "flex",
							marginTop: "10vh",
							zIndex: 99,
							alignItems: "center",
							justifyContent: "center",
							position: "absolute",
							...work,
						}}
					>
						<div
							className="work-list"
							ref={workListRef}
							style={{
								paddingInline: isMobile
									? "4vw"
									: "15vw",
								transition: "all 0.5s",
							}}
						>
							{appList.map((item, index) => (
								<WorkCard
									key={index}
									isMobile={isMobile}
									onClick={() => {
										setSelectedAppImages(
											item.appImages ??
												[]
										);
										setPhoneModalVisible(
											true
										);
									}}
									backgroundImage={
										item.bgImage
									}
									titleImage={
										item.titleImage
									}
									logo={item.logo}
								/>
							))}
						</div>
					</animated.div>
				</Fade>
			)}
			{/* EXPERIENCE */}
			{pageBehaviour === "EXPERIENCE" && (
				<Fade
					in={pageBehaviour === "EXPERIENCE"}
					timeout={{ enter: 1500, exit: 500 }}
				>
					<animated.div
						style={{
							alignSelf: "center",
							display: "flex",
							marginTop: "10vh",
							zIndex: 99,
							alignItems: "center",
							justifyContent: "center",
							position: "absolute",
							...experience,
						}}
					>
						<div
							className="experience-list"
							ref={experienceListRef}
						>
							{experienceList.map((item, index) => (
								<TimelineCard
									key={index}
									isMobile={isMobile}
									index={index}
									pageBehaviour={
										pageBehaviour
									}
									appLogo={item.appLogo}
									tools={item.tools}
									dateRange={item.dateRange}
									title={item.title}
									description={
										item.description
									}
									onLogoClick={(
										appImages
									) => {
										setSelectedAppImages(
											appImages ??
												[]
										);
										setPhoneModalVisible(
											true
										);
									}}
								/>
							))}
						</div>
					</animated.div>
				</Fade>
			)}
			{/* CONTACT */}
			{pageBehaviour === "CONTACT" && (
				<Fade
					in={pageBehaviour === "CONTACT"}
					timeout={{ enter: 1500, exit: 500 }}
				>
					<animated.div
						style={{
							alignSelf: "center",
							marginTop: "10vh",
							zIndex: 99,
							alignItems: "center",
							justifyContent: "center",
							position: "absolute",
							...contact,
						}}
					>
						<div
							style={{
								display: "flex",
								alignItems: "center",
							}}
						>
							<img
								alt="img"
								src={require("../../assets/icons/avatar.png")}
								style={{
									height: "20vh",
									width: "20vw",
									maxHeight: 300,
									maxWidth: 150,
									borderRadius: 99,
									objectFit: "contain",
									marginRight: 32,
									// marginBottom: 24,
									pointerEvents: "none",
									userSelect: "none",
								}}
							/>
							<div className="contact-card-container">
								<div
									style={{
										display: "flex",
										alignItems:
											"center",
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection:
												"column",
											alignItems:
												"baseline",
										}}
									>
										<p
											style={{
												marginBlock: 0,
												fontSize: 32,
												fontWeight:
													"bold",
											}}
										>
											Yilmaz Yakut
										</p>
										<p
											style={{
												marginBlock: 0,
												fontSize: 10,
												marginTop: 8,
											}}
										>
											REACT NATIVE
											DEVELOPER
										</p>
									</div>
								</div>
							</div>
						</div>

						{contactInfo.map((item, index) => (
							<RowInfo
								isMobile={isMobile}
								onClick={() => {
									if (
										item.contactId ===
										"email"
									) {
										window.open(
											"mailto:yilmazykt@hotmail.com",
											"_blank"
										);
									} else if (
										item.contactId ===
										"linkedin"
									) {
										window.open(
											"https://www.linkedin.com/in/yilmazyakut/",
											"_blank"
										);
									} else if (
										item.contactId ===
										"github"
									) {
										window.open(
											"https://github.com/yilmazyakut",
											"_blank"
										);
									}
								}}
								icon={item.icon}
								text={item.text}
								index={index}
							/>
						))}
					</animated.div>
				</Fade>
			)}
			<Lottie
				className="home-code-anim"
				animationData={CodeAnimation}
			/>
			{/* {isLoadingAppImages && (
				<div className="spinner-container">
					<Zoom
						in={isLoadingAppImages}
						timeout={{ enter: 500, exit: 500 }}
					>
						<div
							className="spinner icon-spinner-2"
							aria-hidden="true"
						/>
					</Zoom>

					<Fade
						in={isLoadingAppImages}
						timeout={{ enter: 500, exit: 500 }}
					>
						<div className="spinner-overlay-bg" />
					</Fade>
				</div>
			)} */}
			{selectedAppImages.length > 0 && (
				<PhoneModal
					isMobile={isMobile}
					isVisible={phoneModalVisible}
					onCloseModal={() => {
						setPhoneModalVisible(false);
						setTimeout(() => {
							setSelectedAppImages([]);
						}, 600);
					}}
					selectedAppImages={selectedAppImages}
				/>
			)}
		</div>
	);
};

export default HomePage;
