import { ToolItem, ToolName } from "./allTypes";

export const toolsData: ToolItem[] = [
	{
		name: "Firebase",
		color: "#c27303",
	},
	{
		name: "Typescript",
		color: "#132b75",
	},
	{
		name: "Javascript",
		color: "#847f04",
	},
	{
		name: "Cloud Firestore",
		color: "#88a61b",
	},
	{
		name: "SQLite",
		color: "#ec6767",
	},
	{
		name: "PostgreSQL",
		color: "#3e5ebc",
	},
	{
		name: "Redux",
		color: "#9b263d",
	},
	{
		name: "Overmind",
		color: "#7e8e5b",
	},
	{
		name: "GraphQL",
		color: "#3e7262",
	},
	{
		name: "Rest API",
		color: "#04adad",
	},
	{
		name: "Airtable",
		color: "#978a13",
	},
	{
		name: "Figma",
		color: "#166d58",
	},
	{
		name: "Adobe XD",
		color: "#ea95e6",
	},
	{
		name: "Google Analytics",
		color: "#89379c",
	},
	{
		name: "Meta SDK",
		color: "#3498DB",
	},
	{
		name: "Posthog",
		color: "#bd9246",
	},
	{
		name: "Sentry",
		color: "#FF8C00",
	},
	{
		name: "Jira",
		color: "#30a1ec",
	},
	{
		name: "Linear",
		color: "#3961d2",
	},
	{
		name: "Slack",
		color: "#278a95",
	},
	{
		name: "GitHub",
		color: "#181818",
	},
	{
		name: "AdMob",
		color: "#fa5a3a",
	},
	{
		name: "i18next",
		color: "#298159",
	},
];

const getToolList = (toolNames: ToolName[]): ToolItem[] => {
	return toolNames
		.map((toolName) => {
			const matchedTool = toolsData.find(
				(tool) => tool.name === toolName
			);
			return matchedTool
				? {
						name: matchedTool.name,
						color: matchedTool.color,
				  }
				: null;
		})
		.filter(Boolean) as ToolItem[];
};

export const experienceList = [
	{
		appLogo: [
			{
				appId: "wezzomart",
				image: require("../assets/icons/wezzomart-logo.png"),
			},
		],
		tools: getToolList([
			"Airtable",
			"Figma",
			"Firebase",
			"Meta SDK",
			"GitHub",
			"Google Analytics",
			"GraphQL",
			"Jira",
			"Redux",
			"Sentry",
			"Typescript",
			"i18next",
		]),
		dateRange: [
			`Aug 2023`,
			`Sep 2023`,
			`Oct 2023`,
			`Nov 2023`,
			`Dec 2023`,
		],
		title: "Wezzomart",

		description:
			"My latest project is Wezzomart, an online shopping application aiming to establish an online marketplace from Turkey to Iraq. Throughout its development, my focus was on creating a seamless user experience that prioritizes efficiency and user satisfaction.",
	},
	{
		appLogo: [
			{
				appId: "trash2cash",
				image: require("../assets/icons/trash2cash-logo.png"),
			},
		],
		tools: getToolList([
			"Figma",
			"GitHub",
			"Google Analytics",
			"Linear",
			"Meta SDK",
			"Redux",
			"Rest API",
			"Typescript",
		]),
		dateRange: [`May 2023`, `Jun 2023`, `Jul 2023`],
		title: "Trash-2-Cash",
		description:
			"Ventured into environmental sustainability with Trash-2-Cash—an app where users upload a photo as they dispose of their waste in the bin, and it goes through approval from other users. By ensuring the accuracy of waste disposal, users earn rewards and discounts from partnered brands, turning waste management into a rewarding and engaging experience.",
	},
	{
		appLogo: [
			{
				appId: "vinden",
				image: require("../assets/icons/vinden-logo.png"),
			},
			{
				appId: "move",
				image: require("../assets/icons/move-logo.png"),
			},
		],
		tools: getToolList([
			"Airtable",
			"Figma",
			"GitHub",
			"GraphQL",
			"Linear",
			"Overmind",
			"Posthog",
			"Sentry",
			"Typescript",
			"i18next",
		]),
		dateRange: [
			`Jul 2022`,
			`Aug 2022`,
			`Sep 2022`,
			`Oct 2022`,
			`Nov 2022`,
			`Dec 2022`,
			`Jan 2023`,
			`Feb 2023`,
			`Mar 2023`,
			`Apr 2023`,
		],
		title: "Vinden",
		description:
			"Joined the Vinden project—a company specializing in renting shelves in warehouses. Users send excess items from their homes to these warehouses. In this role, I worked as a developer on VindenApp. Additionally, I completed the MoveApp application for truck drivers in the company, providing essential information on stops, delivery, or pickup, and incorporating features like a QR reading system and product photography.",
	},
	{
		appLogo: [
			{
				appId: "faireez",
				image: require("../assets/icons/faireez-logo.png"),
			},
		],
		tools: getToolList([
			"Figma",
			"GitHub",
			"Linear",
			"Google Analytics",
			"Redux",
			"Rest API",
			"Typescript",
			"i18next",
		]),
		dateRange: [`May 2022`, `Jun 2022`],
		title: "Faireez",

		description:
			"Kicked off web development with Faireez, a React-based web app tailored for a cleaning company. It features a mobile-friendly UI, making it easy for customers to schedule and manage cleaning services effortlessly.",
	},
	{
		appLogo: [
			{
				appId: "alphabuddy",
				image: require("../assets/icons/alphabuddy-logo.png"),
			},
		],
		tools: getToolList([
			"Figma",
			"Airtable",
			"GitHub",
			"Google Analytics",
			"Javascript",
			"Redux",
			"Slack",
		]),
		dateRange: [`Mar 2022`, `Apr 2022`],
		title: "Alphabuddy",

		description:
			"Contributed to early childhood education with Alphabuddy—an interactive game targeting children aged 2-6 to teach them the alphabet. The app utilizes animations and drawing exercises to make learning the alphabet a playful and enjoyable experience for young minds.",
	},
	{
		appLogo: [
			{
				appId: "pozymatch",
				image: require("../assets/icons/pozy-logo.png"),
			},
		],
		tools: getToolList([
			"Figma",
			"AdMob",
			"GitHub",
			"Redux",
			"Rest API",
			"Typescript",
		]),
		dateRange: [`Jan 2022`, `Feb 2022`],
		title: "Pozy Match",

		description:
			"Stepping into the gaming world with Pozy Match—a React Native game designed for a simple and engaging user experience. Users match cards, earn points, and progress through levels by finding pairs. A fun addition to the mobile app journey!",
	},
	{
		appLogo: [
			{
				appId: "qnote",
				image: require("../assets/icons/qnote-logo.png"),
			},
		],
		tools: getToolList([
			"Figma",
			"Cloud Firestore",
			"Firebase",
			"GitHub",
			"Redux",
			"SQLite",
			"Typescript",
			"AdMob",
		]),
		dateRange: [`Nov 2021`, `Dec 2021`],
		title: "Q-Note",

		description:
			"Next on the list is Q-Note, a sleek note-taking app with offline and online capabilities. Users can effortlessly jot down notes, create to-do lists, and set alarms for a seamless organization experience.",
	},
	{
		appLogo: [
			{
				appId: "casher",
				image: require("../assets/icons/casher-logo.png"),
			},
		],
		tools: getToolList([
			"Figma",
			"Firebase",
			"GitHub",
			"PostgreSQL",
			"Redux",
			"Rest API",
			"Typescript",
			"AdMob",
		]),
		dateRange: [`Aug 2021`, `Sep 2021`, `Oct 2021`],
		title: "Casher",

		description:
			"Started my React Native adventure with Casher—an app where users tackle tasks to earn points. They can create accounts, log in, and enjoy a gamified experience.",
	},
];

export const appList = [
	{
		appId: "wezzomart",
		bgImage: require("../assets/icons/wezzomart-bg.jpg"),
		titleImage: require("../assets/icons/wezzomart-title.png"),
		logo: require("../assets/icons/wezzomart-logo.png"),
		appImages: [
			{
				id: "1",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/wezzomart/1.png")}
					/>
				),
			},
			{
				id: "2",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/wezzomart/2.png")}
					/>
				),
			},
			{
				id: "3",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/wezzomart/3.png")}
					/>
				),
			},
			{
				id: "4",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/wezzomart/4.png")}
					/>
				),
			},
			{
				id: "5",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/wezzomart/5.png")}
					/>
				),
			},
			{
				id: "6",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/wezzomart/6.png")}
					/>
				),
			},
			{
				id: "7",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/wezzomart/7.png")}
					/>
				),
			},
			{
				id: "8",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/wezzomart/8.png")}
					/>
				),
			},
			{
				id: "9",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/wezzomart/9.png")}
					/>
				),
			},
			{
				id: "10",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/wezzomart/10.png")}
					/>
				),
			},
			{
				id: "11",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/wezzomart/11.png")}
					/>
				),
			},
			{
				id: "12",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/wezzomart/12.png")}
					/>
				),
			},
			{
				id: "13",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/wezzomart/13.png")}
					/>
				),
			},
			{
				id: "14",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/wezzomart/14.png")}
					/>
				),
			},
		],
	},
	{
		appId: "vinden",
		bgImage: require("../assets/icons/vinden-bg.jpg"),
		titleImage: require("../assets/icons/vinden-title.png"),
		logo: require("../assets/icons/vinden-logo.png"),
		appImages: [
			{
				id: "1",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/vinden/1.png")}
					/>
				),
			},
			{
				id: "2",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/vinden/2.png")}
					/>
				),
			},
			{
				id: "3",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/vinden/3.png")}
					/>
				),
			},
			{
				id: "4",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/vinden/4.png")}
					/>
				),
			},
			{
				id: "5",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/vinden/5.png")}
					/>
				),
			},
			{
				id: "6",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/vinden/6.png")}
					/>
				),
			},
			{
				id: "7",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/vinden/7.png")}
					/>
				),
			},
			{
				id: "8",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/vinden/8.png")}
					/>
				),
			},
			{
				id: "9",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/vinden/9.png")}
					/>
				),
			},
			{
				id: "10",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/vinden/10.png")}
					/>
				),
			},
		],
	},
	{
		appId: "move",
		bgImage: require("../assets/icons/move-bg.jpg"),
		titleImage: require("../assets/icons/move-title.png"),
		logo: require("../assets/icons/move-logo.png"),
		appImages: [
			{
				id: "1",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/move/1.png")}
					/>
				),
			},
			{
				id: "2",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/move/2.png")}
					/>
				),
			},
			{
				id: "3",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/move/3.png")}
					/>
				),
			},
			{
				id: "4",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/move/4.png")}
					/>
				),
			},
			{
				id: "5",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/move/5.png")}
					/>
				),
			},
		],
	},
	{
		appId: "trash2cash",
		bgImage: require("../assets/icons/trash2cash-bg.jpg"),
		titleImage: require("../assets/icons/trash2cash-title.png"),
		logo: require("../assets/icons/trash2cash-logo.png"),
		appImages: [
			{
				id: "1",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/trash2cash/1.png")}
					/>
				),
			},
			{
				id: "2",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/trash2cash/2.png")}
					/>
				),
			},
			{
				id: "3",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/trash2cash/3.png")}
					/>
				),
			},
			{
				id: "4",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/trash2cash/4.png")}
					/>
				),
			},
			{
				id: "5",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/trash2cash/5.png")}
					/>
				),
			},
			{
				id: "6",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/trash2cash/6.png")}
					/>
				),
			},
			{
				id: "7",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/trash2cash/7.png")}
					/>
				),
			},
			{
				id: "8",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/trash2cash/8.png")}
					/>
				),
			},
			{
				id: "9",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/trash2cash/9.png")}
					/>
				),
			},
			{
				id: "10",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/trash2cash/10.png")}
					/>
				),
			},
		],
	},
	{
		appId: "pozymatch",
		bgImage: require("../assets/icons/pozy-bg.jpg"),
		titleImage: require("../assets/icons/pozy-title.png"),
		logo: require("../assets/icons/pozy-logo.png"),
		appImages: [
			{
				id: "1",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/pozymatch/1.png")}
					/>
				),
			},
			{
				id: "2",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/pozymatch/2.png")}
					/>
				),
			},
			{
				id: "3",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/pozymatch/3.png")}
					/>
				),
			},
			{
				id: "4",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/pozymatch/4.png")}
					/>
				),
			},
			{
				id: "5",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/pozymatch/5.png")}
					/>
				),
			},
		],
	},
	{
		appId: "qnote",
		bgImage: require("../assets/icons/qnote-bg.jpg"),
		titleImage: require("../assets/icons/qnote-title.png"),
		logo: require("../assets/icons/qnote-logo.png"),
		appImages: [
			{
				id: "1",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/qnote/1.png")}
					/>
				),
			},
			{
				id: "2",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/qnote/2.png")}
					/>
				),
			},
			{
				id: "3",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/qnote/3.png")}
					/>
				),
			},
			{
				id: "4",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/qnote/4.png")}
					/>
				),
			},
			{
				id: "5",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/qnote/5.png")}
					/>
				),
			},
		],
	},
	{
		appId: "faireez",
		bgImage: require("../assets/icons/faireez-bg.jpg"),
		titleImage: require("../assets/icons/faireez-title.png"),
		logo: require("../assets/icons/faireez-logo.png"),
		appImages: [
			{
				id: "1",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/faireez/1.png")}
					/>
				),
			},
			{
				id: "2",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/faireez/2.png")}
					/>
				),
			},
			{
				id: "3",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/faireez/3.png")}
					/>
				),
			},
			{
				id: "4",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/faireez/4.png")}
					/>
				),
			},
			{
				id: "5",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/faireez/5.png")}
					/>
				),
			},
			{
				id: "6",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/faireez/6.png")}
					/>
				),
			},
			{
				id: "7",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/faireez/7.png")}
					/>
				),
			},
			{
				id: "8",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/faireez/8.png")}
					/>
				),
			},
			{
				id: "9",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/faireez/9.png")}
					/>
				),
			},
		],
	},
	{
		appId: "casher",
		bgImage: require("../assets/icons/casher-bg.jpg"),
		titleImage: require("../assets/icons/casher-title.png"),
		logo: require("../assets/icons/casher-logo.png"),
		appImages: [
			{
				id: "1",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/casher/1.png")}
					/>
				),
			},
			{
				id: "2",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/casher/2.png")}
					/>
				),
			},
			{
				id: "3",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/casher/3.png")}
					/>
				),
			},
			{
				id: "4",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/casher/4.png")}
					/>
				),
			},
			{
				id: "5",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/casher/5.png")}
					/>
				),
			},
			{
				id: "6",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/casher/6.png")}
					/>
				),
			},
			{
				id: "7",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/casher/7.png")}
					/>
				),
			},
		],
	},
	{
		appId: "alphabuddy",
		bgImage: require("../assets/icons/alphabuddy-bg.jpg"),
		titleImage: require("../assets/icons/alphabuddy-title.png"),
		logo: require("../assets/icons/alphabuddy-logo.png"),
		appImages: [
			{
				id: "1",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/alphabuddy/1.png")}
					/>
				),
			},
			{
				id: "2",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/alphabuddy/2.png")}
					/>
				),
			},
			{
				id: "3",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/alphabuddy/3.png")}
					/>
				),
			},
			{
				id: "4",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/alphabuddy/4.png")}
					/>
				),
			},
			{
				id: "5",
				renderItem: (
					<img
						alt="img"
						className="app-ss-image"
						src={require("../assets/appImages/alphabuddy/5.png")}
					/>
				),
			},
		],
	},
];

export const contactInfo = [
	{
		icon: require("../assets/icons/email.png"),
		text: "yilmazykt@hotmail.com",
		contactId: "email",
	},
	{
		icon: require("../assets/icons/linkedin.png"),
		text: "linkedin/yilmazyakut",
		contactId: "linkedin",
	},
	{
		icon: require("../assets/icons/github.png"),
		text: "github/yilmazyakut",
		contactId: "github",
	},
];
