import { CSSProperties, FC, MouseEventHandler, ReactNode } from "react";
import { Fade, Modal as MuiModal, Zoom } from "@mui/material";
import "./Modal.css";
interface ModalProps {
	isVisible: boolean;
	children: ReactNode;
	containerStyle?: CSSProperties;
	onBackdropClick: React.ReactEventHandler<{}>;
}

const Modal: FC<ModalProps> = ({
	isVisible = false,
	containerStyle,
	children,
	onBackdropClick,
	...props
}) => {
	return (
		// <MuiModal
		// 	className="mui-modal-box"
		// 	open={isVisible}
		// 	{...props}
		// 	onBackdropClick={onBackdropClick}
		// >
		<div className="mui-modal-box" {...props}>
			<Fade
				in={isVisible}
				style={{ transformOrigin: "center" }}
				timeout={{ enter: 500, exit: 500 }}
			>
				<div
					onClick={onBackdropClick}
					className="modal-overlay-bg"
				/>
			</Fade>
			<Zoom
				in={isVisible}
				style={{ transformOrigin: "center" }}
				timeout={{ enter: 500, exit: 500 }}
			>
				<div
					className="mui-modal-content-box"
					style={containerStyle}
				>
					{children}
				</div>
			</Zoom>
		</div>

		// </MuiModal>
	);
};

export default Modal;
