import "./RowInfo.css";

interface RowInfoProps {
	icon: string;
	text: string;
	index: number;
	onClick: () => void;
	isMobile: boolean;
}

const RowInfo = ({ icon, text, index, onClick, isMobile }: RowInfoProps) => {
	return (
		<div
			className={
				isMobile
					? "row-info-container-mobile"
					: "row-info-container"
			}
			onClick={onClick}
		>
			<div className="row-info-icon-container">
				<img className="row-info-icon" src={icon} alt="Icon" />
			</div>
			<div className="row-info-divider" />

			<p className="row-info-text">{text}</p>
		</div>
	);
};

export default RowInfo;
